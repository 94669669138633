import logo from './logo.svg';
import './App.scss';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import { HomeScreen, ServiceScreen, AboutScreen } from './screens';
import { SplashScreen } from './screens/SplashScreen';
import { PortfolioScreen } from './screens/Portfolio';
import { NavBar } from './components/navbar';

const App = () => {
  return (
    <div className="App">
      <Router>
        <div>
          <Screens />
        </div>
        <NavBar />
      </Router>
    </div>
  );
}

const Screens = () => {
  const location = useLocation();
  return (
    <Switch location={location}>
      <Route exact path="/skills">
        <ServiceScreen />
      </Route>
      <Route exact path="/about">
        <AboutScreen />
      </Route>
      <Route exact path="/portfolio">
        <PortfolioScreen />
      </Route>
      <Route exact path="/home">
        <HomeScreen />
      </Route>
      <Route path="/">
        <SplashScreen />
        <HomeScreen />
      </Route>
    </Switch>
  );
}

export default App;
