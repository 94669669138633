import { useEffect, useState } from "react";
import "./SplashScreen.scss";

const SplashScreen = () => {

    const [lettersVisibility, setLettersVisibility] = useState(SVGPathItemCollection);

    useEffect(() => {
      let timers = [];
      lettersVisibility.forEach( (svgPathItem, i) => {
        timers.push(
          setTimeout(() => {
            setLettersVisibility( (lettersVisibility_) =>
              [...lettersVisibility_.slice(0, i),
              {...lettersVisibility_[i], show: true},
              ...lettersVisibility_.slice(i+1)]
            );
          }, svgPathItem.timeout)
        );
      });
      return () => { timers.forEach(timer => clearTimeout(timer)); };
    }, []);

    return (
      <section className={`section-intro ${lettersVisibility[11].show ? "loaded" : ""}`}>
        <div className="logo">
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="2" style={{ x: 0, y: 0, width: "600px", height: "600px"  }} viewBox="0 0 600 600" xmlSpace="preserve">
            <g>
            {lettersVisibility.map((x,i) => 
              <path className={x.show ? "fill" : "" } key={x.key} d={x.d} />
            )}
            </g>
          </svg>
          <span className={`cursor ${lettersVisibility[10].show && !lettersVisibility[11].show ? "fill" : ""}`}>|</span>
        </div>
      </section>
    );
}

const SVGPathItemCollection = [
  { key: "letter-v", timeout: 10, d: "M77,277v0h-12v-0l-11-50h12l5,23l5-23h12L77,277z" },
  { key: "letter-i", timeout: 100, d: "M107,277h-12v-50h12V277z" },
  { key: "letter-c", timeout: 200, d: "M149,263l9,9c-5,4-10,6-17,6c-14,0-26-12-26-26   c0-14,12-26,26-26c7,0,14,3,19,8l-9,9c-2-2-6-4-10-4   c-7,0-13,6-13,13s6,13,13,13C144,266,147,265,149,263z" },
  { key: "letter-t", timeout: 300, d: "M195,239h-9v38h-12v-38H165v-12h30V239z" },
  { key: "letter-o", timeout: 400, d: "M200,252c0-14,12-26,26-26s26,12,26,26   s-12,26-26,26S200,267,200,252z M212,252c0,8,6,14,14,14   s14-6,14-14s-6-14-14-14S212,245,212,252z" },
  { key: "letter-r", timeout: 500, d: "M286,265l7,10l-10,7l-7-11c-1,0-3,0-4,0v7h-12   v-50h12c12,0,22,10,22,22C293,255,290,261,286,265z M271,258   c5,0,10-4,10-10c0-5-4-10-10-10V258z" },
  { key: "letter-m", timeout: 600, d: "M344,277l-5-23l-5,23h-1h-11h-1l-5-23l-5,23h-12   l11-50v-0h12h0l-0,0l5,24l5-24v-0h12h0l-0,0l11,50H344z" },
  { key: "letter-o2", timeout: 700, d: "M362,252c0-14,12-26,26-26c14,0,26,12,26,26   s-12,26-26,26C373,278,362,267,362,252z M374,252c0,8,6,14,14,14   c8,0,14-6,14-14s-6-14-14-14C380,239,374,245,374,252z" },
  { key: "letter-o3", timeout: 800, d: "M420,252c0-14,12-26,26-26s26,12,26,26   s-12,26-26,26S420,267,420,252z M432,252c0,8,6,14,14,14   s14-6,14-14s-6-14-14-14S432,245,432,252z" },
  { key: "letter-v2", timeout: 900, d: "M495,277v0h-12v-0l-11-50h12l5,23l5-23h12   L495,277z" },
  { key: "letter-e", timeout: 1000, d: "M525,239v6h12v12h-12v8h13v12H513v-50h25v12H525z" },
  { key: "letter-dot", timeout: 2500, d: "M549,278c-2,0-4-2-4-4c0-2,2-4,4-4   c2,0,4,2,4,4C553,276,551,278,549,278z" }
];

export default SplashScreen;