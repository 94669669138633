import { Link, useLocation } from "react-router-dom";
import styles from "./navbar.module.scss";

const routes = [
    { path: '/home', title: "home" },
    { path: '/skills', title: 'what I do' },
    { path: '/portfolio', title: 'showcase'},
    { path: '/about', title: 'about me' }
];

const NavBar = () => {
    const location = useLocation();
    return (
        <div className={styles["nav-sticky"]}>
            <div className={styles["nav-wrapper"]}>
                {routes.map(({ path, title }) =>
                    <Link to={path} className={location.pathname === path || (location.pathname === '/' && path ==='/home') ? styles["disabled"] : ""} key={path+title}>
                        <div className={styles["nav-item"]}>
                            <p>{title}</p>
                        </div>
                    </Link>
                )}
            </div>
        </div>
    );
};

export default NavBar;