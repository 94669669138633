import styles from "./arrowbutton.module.scss";

const ArrowButton = () => {
    return (
        <div className={styles['round']}>
            <span className={styles['round-stroke']} />
            <span className={styles['round-stroke']} />
            <span className={styles['round-stroke']} />
            <span className={styles['round-stroke']} />
        </div>
    )
}

export default ArrowButton;